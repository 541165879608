import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },

    // Catalogs
    // =====================================================================
    {
      path: '/catalogo/productos',
      name: 'catalogs-products',
      component: () => import('@/views/catalogs/products/Products.vue'),
      meta: {
        pageTitle: 'Productos',
        breadcrumb: [
          {
            text: 'Catálogos',
            link: true,
          },
          {
            text: 'Productos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/catalogo/precios',
      name: 'price-lists',
      component: () => import('@/views/catalogs/priceLists/PriceLists.vue'),
      meta: {
        pageTitle: 'Listas de precios',
        breadcrumb: [
          {
            text: 'Catálogos',
            link: true,
          },
          {
            text: 'Listas de precios',
            active: true,
          },
        ],
      },
    },
    {
      path: '/catalogo/regions',
      name: 'catalogs-regions',
      component: () => import('@/views/catalogs/regions/Regions.vue'),
      meta: {
        pageTitle: 'Regiones',
        breadcrumb: [
          {
            text: 'Catálogos',
            link: true,
          },
          {
            text: 'Regiones',
            active: true,
          },
        ],
      },
    },

    // Contacts
    // =====================================================================
    {
      path: '/contactos/clientes',
      name: 'contacts-customers',
      component: () => import('@/views/contacts/customers/Customers.vue'),
      meta: {
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Contactos',
            link: true,
          },
          {
            text: 'Clientes',
            active: true,
          },
        ],
      },
    },

    // Transactions
    // =====================================================================
    {
      path: '/transacciones/cotizaciones',
      name: 'transactions-sales',
      component: () => import('@/views/transactions/sales/Sales.vue'),
      meta: {
        pageTitle: 'Cotizaciones',
        breadcrumb: [
          {
            text: 'Operaciones',
            link: true,
          },
          {
            text: 'Cotizaciones',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transacciones/pedidos',
      name: 'transactions-customer-orders',
      component: () => import('@/views/transactions/customerOrders/CustomerOrders.vue'),
      meta: {
        pageTitle: 'Pedidos',
        breadcrumb: [
          {
            text: 'Operaciones',
            link: true,
          },
          {
            text: 'Pedidos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configuraciones',
      name: 'settings-organization',
      component: () => import('@/views/settings/organization/Organization.vue'),
      meta: {
        pageTitle: 'Configuración',
        breadcrumb: [
          {
            text: 'Configuraciones',
            link: true,
          },
        ],
      },
    },

    // Users-Rols Access
    // =====================================================================
    {
      path: '/Accesos/Roles',
      name: 'access-rols',
      component: () => import('@/views/access/rols/Rols.vue'),
      meta: {
        pageTitle: 'Accesos',
        breadcrumb: [
          {
            text: 'Accesos',
            link: true,
          },
          {
            text: 'Roles',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Accesos/Usuarios',
      name: 'user-settings',
      component: () => import('@/views/access/users/Settings.vue'),
      meta: {
        pageTitle: 'Accesos',
        breadcrumb: [
          {
            text: 'Accesos',
            link: true,
          },
          {
            text: 'users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Accesos/Usuarios',
      name: 'access-users',
      component: () => import('@/views/access/users/Users.vue'),
      meta: {
        pageTitle: 'Accesos',
        breadcrumb: [
          {
            text: 'Accesos',
            link: true,
          },
          {
            text: 'users',
            active: true,
          },
        ],
      },
    },

    // Access
    // =====================================================================
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },

    // Pages
    // =====================================================================
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/ForgotPassword',
      name: 'forgot-password',
      component: () => import('@/views/access/restore/FormWizard.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/ResePassword',
      name: 'reset-password',
      component: () => import('@/views/access/restore/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
